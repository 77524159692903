import { useNavigate } from "react-router-dom";
import "./Heading.scss";
import { IoIosArrowForward } from 'react-icons/io';

const Heading = ({title, t,path}) => {
  const navigate = useNavigate();
  return (
    <div className="heading">
      <h4 className="heading-title"
      onClick={() => navigate(path ? path : '')}
      style={{cursor: 'pointer'}}
      >{title}</h4>
      <div className="heading-sub">
        <p className="heading-subtitle">Nexta</p>
        <IoIosArrowForward />
        <p className="heading-subtitle">{title}</p>
      </div>
    </div>
  );
};

export default Heading;
