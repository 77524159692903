// import React, { useState } from "react";
// import { Form, Input, Button, Card } from "antd";
// import logo from '../../../assets/images/logo-removebg-preview.png';
// import { Link } from "react-router-dom";
// import { FaChevronLeft } from "react-icons/fa";
// const ResetPassword = () => {
//     const [form] = Form.useForm();

//   const onFinish = (values) => {
//   };


//   return (
//     <div className="auth-wrapper">
//         <Card>
//            <Link to="/login" 
//               style={{
//                 display:'flex',
//                 alignItems:'center',
//                 justifyContent:'center',
//               }}
//             >
//                <FaChevronLeft />
//               Back 
//               </Link>
//               <Link to="/login" className="logo-holder"
//                 style={{
//                   display:'flex',
//                   alignItems:'center',
//                   justifyContent:'center',
//                   width: "200px",
//                 //   height: "80px",
//                   margin:'20px auto',
//                 }}
//               >
//                 <img src={logo} alt="logo" style={{width:'100%', height:'100%'}} />
//               </Link>
//         <p className="form-hint-h">Please enter your new password</p>
//         <Form
//           form={form}
//           layout="vertical"
//           name="login-form"
//           onFinish={onFinish}
//         >
//           <Form.Item
//             name="password"
//             label="New Password"
//             className="input-holder"
//             rules={[
//               { required: true, message: "Please enter your new password" },
//               { min: 8, message: "Password must be at least 8 characters" },
//               { max: 20, message: "Password must be less than 20 characters" },
//             ]}
//             hasFeedback
//           >
//             <Input.Password placeholder="New Password" />
//           </Form.Item>

//           <Form.Item
//             name="confirm"
//             label="Confirm Password"
//             dependencies={['password']}
//             className="input-holder"
//             hasFeedback
//             rules={[
//               { required: true, message: "Please confirm your new password" },
//               { min: 8, message: "Password must be at least 8 characters" },
//               { max: 20, message: "Password must be less than 20 characters" },
//               ({ getFieldValue }) => ({
//                 validator(_, value) {
//                   if (!value || getFieldValue('password') === value) {
//                     return Promise.resolve();
//                   }
//                   return Promise.reject(new Error("The two passwords do not match"));
//                 },
//               }),
//             ]}
//           >
//             <Input.Password placeholder="Confirm Password" />
//           </Form.Item>

//           <Button type="primary" htmlType="submit" block loading={false}>
//             Change Password
//           </Button>
//         </Form>
//         </Card>
//       </div>
//   );
// };

// export default ResetPassword;


import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-removebg-preview.png';
import { Button, Card, Checkbox, Form, Input, Space, App, Flex } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { resetPassword } from '../../../Network/auth';
import { useMutation } from '@tanstack/react-query';
const ForgotPassword = () => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingAuth, setLoadingAuth] = useState(false);
  const { mutate: onResetPassword } = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: (body) => resetPassword(body),
    onSuccess: (res) => {
      setLoadingAuth(false);
      console.log('Login  res:', res);
      if (!res) {
        return message.error("Please check your Password and try again.");
      } 
      message.success('Password changed successfully');
      navigate('/login');
    },
    onError: (error) => {
      setLoadingAuth(false);
      console.log('Login  error:', error);
      message.error(
        'Please check your Password and try again.'
      );
    },
  });
  const onFinish = (values) => {
    // console.log('Success:', values);
    setLoadingAuth(true);
    onResetPassword({
        token: "",
        new_password: values.password,
      });
  };
  return (
    <div
      className="login"
      style={{
        justifyContent:'center',
      }}
    >
        <Card
          className="login-card"
          style={{
            background:'#f9f6f6',
          }}
        >
            <div className=""
              style={{
                display:'flex',
                alignItems:'start',
                justifyContent:'space-between',
                flexDirection:'column',
                // gap:'10px',
                marginBottom:'20px',
              }}
            >
            <Link to="/forgot-password" 
              style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
              }}
            >
               <FaChevronLeft />
              Back 
              </Link>
              <Link to="/login" className="logo-holder"
                style={{
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  width: "200px",
                //   height: "80px",
                  margin:'20px auto',
                }}
              >
                <img src={logo} alt="logo" style={{width:'100%', height:'100%'}} />
              </Link>
              <p className="login-sub"
                style={{
                  width:'100%',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  textAlign:'center',
                  color:'#333',
                  fontSize:'14px',
                }}
              >
               Please enter your new password
              </p>
            </div>
          <Form
            layout="vertical"
            name="login-form"
            onFinish={onFinish}
            form={form}
            className="login-form"
          >
            <Form.Item
              name="password"
              label="New Password"
              className="input-holder"
              rules={[
                { required: true, message: "Please enter your new password" },
                { min: 8, message: "Password must be at least 8 characters" },
                { max: 20, message: "Password must be less than 20 characters" },
              ]}
            >
              <Input.Password placeholder="New Password" />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              className="input-holder"
              dependencies={['password']}
              hasFeedback
              rules={[
                { required: true, message: "Please confirm your new password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The two passwords do not match"));
                  },
                })
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingAuth}
                block
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      {/* </div> */}
    </div>
  );
};

export default ForgotPassword;
