import { Button, Drawer, Flex, Image } from 'antd';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import logoLight from '../assets/images/logo-removebg-preview.png';
import { IoAnalyticsOutline, IoChatbubbleEllipsesSharp, IoClose } from 'react-icons/io5';
import { NavLink, useNavigate } from 'react-router-dom';
import { GrDashboard } from 'react-icons/gr';
import { FaUsers } from 'react-icons/fa6';
import logo from '../assets/images/mainLogo.png';
import { DownOutlined, BranchesOutlined } from '@ant-design/icons';
const DrawerLinks = ({t,open,setOpen,admin}) => {

    const { currentLang,Features, darkMode } = useSelector(
        (state) => state.user
      );
      const navigate = useNavigate();
      const onClose = () => {
        setOpen(false);
      };
      const itemRes = [
        {
          key: '1',
          url: '/',
          label: <span> {t.dashboard} </span>,
        //   onClick: () => navigate('/'),
          icon: <GrDashboard className="navbar-icon" />,
        },
        {
          key: '2',
          url: '/analytics',
          label: <span> {t.analytics} </span>,
        //   onClick: () => navigate('/analytics'),
          icon: <IoAnalyticsOutline className="navbar-icon" />,
        },
        // {
        //   key: '3',
        //   url: '/SalesAnalysis',
        //   label: <span> {t.SalesAnalysis} </span>,
        //   onClick: () => navigate('/SalesAnalysis'),
        //   icon: <FaMoneyCheckDollar className="navbar-icon" />,
        // },
        // {
        //   key: '4',
        //   url: '/MarketingPromotions',
        //   label: <span> {t.MarketingPromotions} </span>,
        //   onClick: () => navigate('/MarketingPromotions'),
        //   icon: <FaChartLine className="navbar-icon" />,
        // },
        // {
        //   key: '5',
        //   url: '/InventoryManagement',
        //   label: <span> {t.InventoryManagement} </span>,
        //   onClick: () => navigate('/InventoryManagement'),
        //   icon: <FaBoxes className="navbar-icon" />,
        // },
          Features?.includes('Ask Store') && {        
            key: '6',
            url: '/asknexta',
            label: <span> {t.asknexta} </span>,
            // onClick: () => navigate('/asknexta'),
            icon: <IoChatbubbleEllipsesSharp className="navbar-icon" />,
          },
      ];
      const itemResAdmin = [
        {
          key: '1',
          url: '/admin',
          label: <span> {t.dashboard} </span>,
        //   onClick: () => navigate('/admin'),
          icon: <GrDashboard className="navbar-icon" />,
        },
        {
          key: '2',
          url: '/admin/branches',
          label: <span> {t.branches} </span>,
        //   onClick: () => navigate('/admin/branches'),
          icon: <BranchesOutlined className="navbar-icon" />,
        },
        {
          key: '3',
          url: '/admin/customers',
          label: <span> {t.customers} </span>,
        //   onClick: () => navigate('/admin/customers'),
          icon: <FaUsers className="navbar-icon" />,
        },
      ];
      const items = admin ? itemResAdmin : itemRes
  return (
    <>
           <Drawer 
      onClose={onClose} 
      open={open}
      placement={currentLang === "ar" ? "right" : "left"}
        // style={{
        //   background:"#242424",
        // }}
        // closeIcon={false}
        headerStyle={{ 
          padding:'15px 10px',
          background: 'none',
          border: 'none',
         }
        }
        bodyStyle={{ 
          padding:'10px',
         }
        }
        // extra={<IoClose size={20} onClick={onClose} color='black' />}
        className='drawer-header'
      >
        {/* <div 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // width: '100%',
            width: '150px',
            marginBottom: 20,
            cursor: 'pointer',
          }}
          onClick={()=> {
            navigator("/");
            onClose();
          }}
        >
          <img
           src={darkMode ? logo : logoLight}
           alt="logo" 
           preview={false}
        //    width={120}
            // height={70}
            style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                cursor: 'pointer',
                border: 'none !important',
              }}
          />
        </div> */}
        <div 
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            flexDirection: 'column',
            width: '100%',
            gap: 50,
          }}
          className='nav-links-drawer'
        >
          {/* <NavLinksItems t={t} onClose={onClose} /> */}
          {
            items?.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  onClick={onClose}
                  to={item?.url}
                  className={({ isActive }) => {
                    return isActive ? 'activeLink link-header' : 'link-header';
                }}
                >
                <Flex align="center" gap="5px">
                  {item?.icon}
                  {item?.label}
                </Flex>
                </NavLink>
              )
            })
          }
        </div>
      </Drawer>
    </>
  )
}

export default DrawerLinks