import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";

 

 export const getBranches = catchAsync(async () => {
    const { data } = await axiosInstance.get(`/admin/branches/`);
    return data;
  });
 export const getBranchesByUser  = catchAsync(async (branchId) => {
  // /admin/branches/{branch_id}
    const { data } = await axiosInstance.get(`/admin/branches/${branchId}`);
    return data;
  });

 export const addBranch = catchAsync(async (body) => {
    const { data } = await axiosInstance.post(`/admin/branches/`, body);
    return data;
  });
 export const updateBranch = catchAsync(async ({ body, id }) => {
    const { data } = await axiosInstance.put(`/admin/branches/${id}`, body);
    return data;
  });
 export const deleteBranch = catchAsync(async (id) => {
    const { data } = await axiosInstance.delete(`/admin/branches/${id}`);
    return data;
  });