import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-removebg-preview.png';
import { Button, Card, Form, Input, App } from 'antd';
import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { authLogin } from '../../../Network/auth';

const PhoneVerify = () => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [timer, setTimer] = useState(160);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const { mutate: onAuthLogin } = useMutation({
    mutationKey: ['login'],
    mutationFn: (body) => authLogin(body),
    onSuccess: (res) => {
      setLoadingAuth(false);
      console.log('Login res:', res);
      if (!res) {
        return message.error(
          'Please enter the correct email and password. Note that both fields may be case sensitive'
        );
      }
      message.success('Login success');
      navigate('/');
    },
    onError: (error) => {
      setLoadingAuth(false);
      console.log('Login error:', error);
      message.error(
        'Please enter the correct email and password. Note that both fields may be case sensitive'
      );
    },
  });

  useEffect(() => {
    let timerInterval = null;

    if (isTimerRunning && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [isTimerRunning, timer]);

  const onFinish = (values) => {
    // navigate('/phone-verification');
    // setLoadingAuth(true);
    // onAuthLogin({
    //   email: values.email,
    //   password: values.password,
    // });
  };

  return (
    <div
      className="login"
      style={{
        justifyContent: 'center',
      }}
    >
      <Card
        className="login-card"
        style={{
          background: '#f9f6f6',
        }}
      >
        <div
          className=""
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginBottom: '20px',
          }}
        >
          <Link
            to="/forgot-password"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
               <FaChevronLeft />
            Back
          </Link>
          <Link
            to="/login"
            className="logo-holder"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '200px',
              margin: '0px auto',
            }}
          >
            <img src={logo} alt="logo" style={{ width: '100%', height: '100%' }} />
          </Link>
        </div>
        <Form
          layout="vertical"
          name="login-form"
          onFinish={onFinish}
          form={form}
          className="login-form"
        >
          <Form.Item
            name="otp"
            label="Enter verification code"
            rules={[
              {
                required: true,
                message: 'Please input your verification code!',
              },
            ]}
            style={{ marginBottom: '15px' }}
          >
            <Input.OTP
              placeholder="Enter verification code"
              autoFocus
              length={5}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <p className="resend-verify" style={{ marginBottom: '20px' , textAlign:'center'}}>
          Resend code after {Math.max(timer, 0)} second
          </p>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingAuth}
              block
            >
              Verify
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default PhoneVerify;
