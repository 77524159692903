import { Button, Card, Input, Space, Table } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const DynamicTableAnt = ({
  columns,
  data,
  loading,
  setSearchText,
  titleBtn,
  Placeholder,
  pathBtn,
  buttonModal,
  pageCount,
  totalCount,
  setPageCount,
  searchInput,
}) => {
  const { currentLang, darkMode } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handlePageChange = (pageNumber) => {
    setPageCount(pageNumber?.current);
  };

  return (
    <div className="table-responsive">
      <Card>
        <Space
          style={{
            width: '100%',
            marginBottom: 16,
            justifyContent: searchInput ? 'flex-end' : 'space-between',
            alignItems: 'center',
          }}
          wrap
        >
          {searchInput ? null : (
            <Input.Search
              placeholder={
                Placeholder
                  ? Placeholder
                  : currentLang === 'en'
                  ? 'search...'
                  : 'بحث...'
              }
              onSearch={(value) => setSearchText(value)}
              onChange={(value) => setSearchText(value.target.value)}
              style={{ width: 270 }}
              allowClear
              prefix={<SearchOutlined />}
              className={`search-input-admin ${
                darkMode ? 'dark-search' : 'light-search'
              }`}
            />
          )}
          {buttonModal ? (
            buttonModal
          ) : (
            titleBtn &&
            <Button
              type="primary"
              onClick={() => navigate(pathBtn)}
              className="btn-add-admin"
              icon={
                <PlusOutlined
                  style={{
                    fontSize: '10px',
                  }}
                />
              }
            >
              {titleBtn}
            </Button>
          )}
        </Space>
        <Table
          columns={columns}
          dataSource={data}
          // pagination={true}
          scroll={{ x: 'max-content' }}
          className="table"
          loading={loading}
          rowKey="key"
          pagination={
            totalCount
              ? {
                  responsive: true,
                  current: pageCount || 1,
                  total: totalCount,
                  pageSize: 10,
                  showSizeChanger: false,
                  defaultPageSize: 10,
                }
              : true
          }
          onChange={totalCount ? handlePageChange : null}
        />
      </Card>
    </div>
  );
};

export default DynamicTableAnt;
