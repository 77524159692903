import { axiosInstance } from '../services/axiosInstance';
import catchAsync from '../utils/catchAsync';

export const authLogin = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/login/', body);
  return data;
});
export const authForget = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/forget_password/', body);
  return data;
});
export const resetPassword = catchAsync(async (body) => {
  const { data } = await axiosInstance.post('/reset_password/', body);
  return data;
});
