import { App, Button, Card, Flex, Input, Popconfirm, Table, Tag } from 'antd';
import React, { useState } from 'react';
import Heading from '../../Components/Common/Heading/Heading';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { deleteUser, getUsers } from '../../Network/users';
import { PlusOutlined,DeleteOutlined,EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ModalAppAddCustomer } from '../../Components/Common/ModalApp/ModalApp';
const Customers = ({ t }) => {
  const [searchText, setSearchText] = useState('');
  const [open,setOpen] = useState(false);
  const [edit,setEdit] = useState(false);
  const [editId,setEditId] = useState(null);
  const { message } = App.useApp();
  const [pageCount, setPageCount] = useState(1);
  const { darkMode } = useSelector((state) => state.user);
  const client = useQueryClient();
  const { data: users, isLoading } = useQuery({
    queryKey: [queryKeys.getUsers, pageCount],
    queryFn: () => getUsers(pageCount),
    keepPreviousData: true,
  });
  const { mutate: deleteUserMutate } = useMutation({
    mutationKey: ['deleteUser'],
    mutationFn: (id) => deleteUser(id),
    onSuccess: (data) => {
      if (data?.message?.status === 500) {
        message.error(data?.message);
        return false;
      }
      client.invalidateQueries({
        queryKey: [queryKeys.getUsers, pageCount],
      });
      message.success("User deleted successfully");
    },
    onError: (error) => {
      message.error(error);
    },
  });
  const columns = [
    // {
    //   title: t.CustomerNumber,
    //   dataIndex: 'UserID',
    //   key: 'UserID',
    // },
    {
      title: t.BrandName,
      dataIndex: 'BrandName',
      key: 'BrandName',
      filteredValue: [searchText],
      onFilter: (value, record) => {
        const name = String(record.Client).toLowerCase();
        const searchValue = value.toLowerCase();
        return name.includes(searchValue);
      },
    },
    {
      title: t.phoneNumber,
      dataIndex: 'PhoneNumber',
      key: 'PhoneNumber',
    },
    {
      title: t.Email,
      dataIndex: 'CustomerEmail',
      key: 'CustomerEmail',
    },
    {
      title: t.JoinDate,
      dataIndex: 'JoinDate',
      key: 'JoinDate',
    },
    {
      title: t.ExpiryDate,
      dataIndex: 'ExpiryDate',
      key: 'ExpiryDate',
    },
    {
      title: t.price,
      dataIndex: 'SubscriptionPrice',
      key: 'SubscriptionPrice',
    },
    // {
    //   title: t.features,
    //   dataIndex: 'Features',
    //   key: 'Features',
    //   // width: 400,
    //   render: (features) => {
    //     return (
    //       <Flex gap="5px" align='center'
    //       style={{
    //         maxWidth:300,
    //         overflowX: 'auto',
    //         whiteSpace: 'nowrap',
    //         // background: 'rgba(0, 128, 0, 0.1)',
    //         padding: '0px 0px 2px',
    //         borderRadius: '4px',
    //         border: 'none',
    //         margin:0,
    //         // cursor: 'pointer'
    //       }}
    //       >
    //         {
    //           features?.length > 0 ?
    //           features?.map((feature) => (
    //             <Tag
    //             key={feature}
    //             style={{
    //               color: 'green',
    //               background: 'rgba(0, 128, 0, 0.1)',
    //               padding: '7px 12px 8px 12px',
    //               borderRadius: '4px',
    //               border: 'none',
    //               margin:0
    //             }}
    //             >
    //         {feature}
    //       </Tag>
    //           ))
    //           : 'No Features'
    //         }
    //       </Flex>
    //     );
    //   },
    // },
    {
      title: 'Type',
      dataIndex: 'IsSuperUser',
      key: 'IsSuperUser',
      render: (text) => {
        return (
          <Tag bordered={false} color={!text ? 'green' : 'red'}>
            {!text ? 'User' : 'Admin'}
          </Tag>
        );
      },
    },
    {
      title: t.actions,
      key: 'actions',
      width: '150px',
      align: 'center',
      render: (text, record) => (
        
          record?.IsSuperUser === true ? null : (
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  size="small"
                  style={{
                    color: '#fff',
                    backgroundColor: '#1890ff',
                    borderColor: '#1890ff',
                  }}
                  onClick={() => {
                    setOpen(true);
                    setEdit(true);
                    setEditId(record?.key);
                  //   console.log(record?._id);
                  }}
                />
              <Popconfirm
                // title={t.DoyouwanttodeletetheCustomer + ( record?.BrandName)}
                title={t.DoyouwanttodeletetheCustomer}
                description={t.areyousure}
                okText={t.yes}
                okType="danger"
                cancelText={t.no}
                onConfirm={() => deleteUserMutate(record?.key)}
              >
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  size="small"
                  danger
                />
              </Popconfirm>
            </div>
          )
      ),
    },
  ];
  const { pages } = users || {};
  const dataTable = users?.results?.map((user) => ({
      key: user?.id,
      UserID: user?.UserID,
      BrandName: user?.BrandName,
      CustomerEmail: user?.CustomerEmail,
      JoinDate: dayjs(user?.JoinDate).format('DD-MM-YYYY'),
      Features: user?.Features,
      PhoneNumber: user?.PhoneNumber || 'No Number',
      ExpiryDate: user?.ExpiryDate
        ? dayjs(user?.ExpiryDate).format('DD-MM-YYYY')
        : 'No Expiry Date',
      IsSuperUser: user?.IsSuperUser,
      SubscriptionPrice: user?.SubscriptionPrice + ' $',
  }));
  return (
    <div className="customers">
      <Heading title={t.customers} />
      <div
        style={{
          marginTop: '20px',
        }}
      >
        <Card>
          <Flex align='center' justify='space-between'>
          <Input.Search
            placeholder="search for Customer"
            //   onSearch={(value) => setSearchText(value)}
            onChange={(value) => setSearchText(value.target.value)}
            style={{ width: 270 }}
            allowClear
            prefix={<SearchOutlined />}
            className={`search-input-admin ${
              darkMode ? 'dark-search' : 'light-search'
            }`}
          />
           <Button
              type="primary"
              onClick={() => {
                setOpen(true);
                setEdit(false);
                setEditId("");
              }}
              className="btn-add-admin"
              icon={
                <PlusOutlined
                  style={{
                    fontSize: '10px',
                  }}
                />
              }
            >
              {t.addCustomer}
            </Button>
          </Flex>
          <Table
            columns={columns}
            dataSource={dataTable}
            pagination={{
              responsive: true,
              current: pageCount || 1,
              total: pages * 10 || 10,
              pageSize: 10,
              // showSizeChanger: false,
            }}
            onChange={(page) => setPageCount(page?.current)}
            loading={isLoading}
            style={{ marginTop: '20px' }}
            scroll={{ x: 'max-content' }}
          />
        </Card>
      </div>
      <ModalAppAddCustomer open={open} pageCount={pageCount} setOpen={setOpen} edit={edit} setEdit={setEdit} editId={editId} t={t} />
    </div>
  );
};

export default Customers;
