import React, { lazy, Suspense, useEffect } from 'react'
import { Navigate, useRoutes } from 'react-router';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import OwnLayout from '../Layout/OwnLayout';
import Login from '../Pages/auth/Login/Login';
import NotFoundPage from '../Pages/NotFoundPage/NotFoundPage';
import { getStoredToken } from '../services/user-storage';
import DashboardAdmin from '../Pages/DashboardAdmin/DashboardAdmin';
import Customers from '../Pages/Customers/Customers';
import { useQueryClient } from '@tanstack/react-query';
import { onMessageListener, requestForToken } from '../firebase';
import { queryKeys } from '../services/react-query/queryKeys';
import ForgotPassword from '../Pages/auth/ForgotPassword/ForgotPassword';
import PhoneVerify from '../Pages/auth/PhoneVerify/PhoneVerify';
import ResetPassword from '../Pages/auth/ResetPassword/ResetPassword';
import Branches from '../Pages/Branches/Branches';
import { useSelector } from 'react-redux';
// import JobsList from '../Pages/JobsList/JobsList';
// import Inventory from '../Pages/Inventory/Inventory';
const  JobsList  = lazy(() => import('../Pages/JobsList/JobsList'));
const  BlogsList  = lazy(() => import('../Pages/BlogsList/BlogsList'));
const  Inventory  = lazy(() => import('../Pages/Inventory/Inventory'));
const  AddBlog  = lazy(() => import('../Components/Morrow/AddBlog/AddBlog'));
const  AskNexta  = lazy(() => import('../Pages/AskNexta/AskNexta'));
const InventoryManagement = lazy(() => import('../Pages/InventoryManagement/InventoryManagement'))
const SalesAnalysis = lazy(() => import('../Pages/SalesAnalysis/SalesAnalysis'))
const MarketingPromotions = lazy(() => import('../Pages/MarketingPromotions/MarketingPromotions'));
const  PromotinAnalytics = lazy(() => import('../Pages/PromotinAnalytics/PromotinAnalytics'));
const  Analytics = lazy(() => import('../Pages/Analytics/Analytics'));
const  Profile = lazy(() => import('../Pages/Profile/Profile'));
const  EmplyeePerformance = lazy(() => import('../Pages/EmplyeePerformance/EmplyeePerformance'));
const  Emplyee = lazy(() => import('../Pages/Emplyee/Emplyee'));
const Dashboard = lazy(() => import('../Pages/Dashboard/Dashboard'));

const RoutesWrapper = ({t}) => {
  const {admin} = useSelector(state => state.user)
  
  const client = useQueryClient();

    useEffect(() => {
      if(getStoredToken()){
        handelAddNotification();
      }
      onMessageListener((payload) => {
        console.log(payload, "wwwwwwwwwwwwwwwww");
        client.invalidateQueries([queryKeys.notificationList]);
      });
    }, [])
    

    const handelAddNotification = async () => {
      const userToken = await requestForToken();
      console.log("userToken", userToken);
      let body = {
        Token: userToken,
        OS: 3,
      };
      // addPushToken(body).then((res) => {
      //   console.log("res", res);
      // }).catch((err) => {
      //   console.log("err", err);
      // })
  
    }
    const routes = useRoutes([
      {
        path: '*',
        element: <NotFoundPage t={t} />,
      },
      {
        path: '/',
        element: getStoredToken() && !admin ? (
          <OwnLayout t={t} />
        ) : (
          <Navigate to="/login" replace />
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <Dashboard t={t} />
              </Suspense>
            ),
          },
          {
            path: 'analytics',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <Analytics t={t} />
              </Suspense>
            ),
          },
          {
            path: 'promotin-analytics',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <PromotinAnalytics t={t} />
              </Suspense>
            ),
          },
          {
            path: 'MarketingPromotions',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <MarketingPromotions t={t} />
              </Suspense>
            ),
          },
          {
            path: 'SalesAnalysis',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <SalesAnalysis t={t} />
              </Suspense>
            ),
          },
          {
            path: 'emplyee-performance',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <EmplyeePerformance t={t} />
              </Suspense>
            ),
          },
          {
            path: 'emplyee',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <Emplyee t={t} />
              </Suspense>
            ),
          },
          {
            path: 'inventory',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <Inventory t={t} />
              </Suspense>
            ),
          },
          {
            path: 'InventoryManagement',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <InventoryManagement t={t} />
              </Suspense>
            ),
          },
          {
            path: 'asknexta',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <AskNexta t={t} />
              </Suspense>
            ),
          },
          {
            path: 'profile',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <Profile t={t} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '/admin',
        element: getStoredToken() && admin ? (
          <OwnLayout t={t} admin={true} />
        ) : (
          <Navigate to="/admin/login" replace />
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <DashboardAdmin t={t} />
              </Suspense>
            ),
          },
          {
            path: 'blogs',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <BlogsList t={t} />
              </Suspense>
            ),
          },
          {
            path: 'add-blog',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <AddBlog t={t} />
              </Suspense>
            ),
          },
          {
            path: 'edit-blog/:id',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <AddBlog t={t} edit={true} />
              </Suspense>
            ),
          },
          {
            path: 'branches',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <Branches t={t} />
              </Suspense>
            ),
          },
          {
            path: 'customers',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <Customers t={t} />
              </Suspense>
            ),
          },
          {
            path: 'jobs',
            element: (
              <Suspense
                fallback={
                  <Spin
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '85vh',
                    }}
                    size="large"
                    indicator={
                      <LoadingOutlined style={{ fontSize: 40 }} spin />
                    }
                  />
                }
              >
                <JobsList t={t} />
              </Suspense>
            ),
          },
        ]
      },
      {
        path: '/login',
        element: <Login admin={false}  />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword  />,
      },
      {
        path: '/phone-verification',
        element: <PhoneVerify  />,
      },
      {
        path: '/reset-password',
        element: <ResetPassword  />,
      },
      {
        path: '/admin/login',
        element: <Login admin={true} />,
      },
    ]);
    return routes;
}

export default RoutesWrapper