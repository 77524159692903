import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";


export const getFeatures = catchAsync(async () => {
    const { data } = await axiosInstance.get(`/admin/features/`);
    return data;
  });
//   export const getSubFeatures = catchAsync(async () => {
//     const { data } = await axiosInstance.get(`/admin/features/Subfeatures/`);
//     return data;
//   });