import './OwnLayout.scss';
import {
  Layout,
} from 'antd';
import { Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import SidebarMenu from './SidebarMenu';
import SidebarMenuAdmin from './SidebarMenuAdmin';
import HeaderApp from './HeaderApp';
const { Content, Sider } = Layout;
function OwnLayout({ t, admin }) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className="min-layout">
      <Layout>
        <div className="site-layout-responsive">
          <Sider
            width={282}
            breakpoint="lg"
            theme="light"
            collapsed={collapsed}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'sticky',
              left: 0,
              top: 0,
              bottom: 0,
            }}
            onCollapse={(value) => setCollapsed(value)}
          >
            {admin ? (
              <SidebarMenuAdmin collapsed={collapsed} t={t} />
            ) : (
              <SidebarMenu collapsed={collapsed} t={t} />
            )}
          </Sider>
        </div>
        <Layout className="site-layout">
          <HeaderApp t={t} admin={admin} collapsed={collapsed} setCollapsed={setCollapsed} />
          <Content
            style={{
              padding: '20px 24px',
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default OwnLayout;
