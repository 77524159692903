import { App, Button, Checkbox, Col, DatePicker, InputNumber, Form, Input, Divider , Modal, Row, Select, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import dayjs from 'dayjs';
import { getFeatures } from '../../../Network/features';
import { addBranch, getBranchesByUser, updateBranch } from '../../../Network/branches';
import { addUser, getSingleUser, getUsers,  updateUser } from '../../../Network/users';
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from 'react-phone-number-input';

export const ModalAppBranches = memo(({ t, open, setOpen, editId, edit }) => {
  const [form] = Form.useForm();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const client = useQueryClient();
  const {message} = App.useApp();
  const { data: branches, isLoading: loadingBranches } = useQuery({
    queryKey: [queryKeys.getBranches, editId],
    queryFn: () => getBranchesByUser(editId),
    keepPreviousData: true,
    enabled: !!edit,
    // select: (data) => data?.find((item) => item._id === idBranch),
  });
  const { mutate: addBranchMutate } = useMutation({
    mutationKey: ['addBranch'],
    mutationFn: (body) => (edit ? updateBranch({ body, id:branches?._id }) : addBranch(body)),
    onSuccess: (data) => {
      setLoadingAdd(false);
      console.log(data, 'datawwww');
      if (data?.message?.status === 500) {
        message.error(data?.message);
        return false;
      }
      message.success("Branch added successfully");
      client.invalidateQueries({ queryKey: [queryKeys?.getBranches] });
      setOpen(false);
      form.resetFields();
    },
    onError: (error) => {
      setLoadingAdd(false);
      console.log('error:', error);
      // message.error(error);
    },
  });

  
  // const { data: users } = useQuery({
  //   queryKey: [queryKeys.getAllUsers],
  //   queryFn: () => getUsers(1),
  //   keepPreviousData: true,
  // });
  const fetchUsers = async ({ pageParam = 1 }) => {
    const response = await getUsers(pageParam);
    return {
      results: response.results,
      nextPage: response.pages > pageParam ? pageParam + 1 : undefined,
    };
  };
  const {
    data: usersData,
    isLoading: loadingUsers,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.getUsers],
    queryFn: fetchUsers,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    keepPreviousData: true,
  });
  
  const onFinish = (values) => {
    console.log('Success:', values);
    setLoadingAdd(true);
    addBranchMutate({
        UserID: values.UserID,
        SiteID: values.SiteID,
        Location: values.Location,
        TotalCapacity: values.TotalCapacity,
      }
    )
  }
  useEffect(() => {
    if (branches) {
    form.setFieldsValue({
      UserID: branches?.UserID,
      SiteID: branches?.SiteID,
      Location: branches?.Location,
      TotalCapacity: branches?.TotalCapacity,
      ExpiryDate: dayjs(branches?.ExpiryDate) || dayjs(),
    })
    } else {
      form.resetFields();
    }
  }, [editId, form, branches]);
  return (
    <Modal
      title={edit ? 'Edit Branch' : 'Add Branch'}
      open={open}
      onCancel={() => {
        setOpen(false);
        // form.resetFields();
      }}
      footer={null}
      centered
    >
      {
        loadingBranches ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40vh',
        }}>
          <Spin size="large" />
        </div>: (
      <Form
        form={form}
        name="add-admin-form"
        layout="vertical"
        onFinish={onFinish}
        style={{
          marginTop: '20px',
        }}
      >
        <Form.Item
          name="UserID"
          label={t.CustomerName}
          rules={[
            {
              required: true,
            },
          ]}
        >
              <Select
                placeholder={t.BrandName}
                loading={loadingUsers || isFetchingNextPage}
                allowClear
                options={usersData?.pages.flatMap(page => page?.results.map(item => ({
                  label: item?.BrandName,
                  value: item?.id,
                  option: item,
                })))}
                onPopupScroll={(e) => {
                  const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
                  if (bottom) {
                    // console.log('bottom');
                    fetchNextPage();
                  }
                }}
              />
        </Form.Item>
        <Form.Item
          name="Location"
          label={t.branche}
          rules={[
            {
              required: true,
            },
          ]}  
        >
          <Input placeholder={t.branche} />
        </Form.Item>     
        <Form.Item
          name="SiteID"
          label={t.site}
          // hidden
          >
            <Input placeholder={t.site} />
          </Form.Item>
            <Form.Item
              name="TotalCapacity"
              label={t.TotalCapacity}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" 
              placeholder={t.TotalCapacity} 
              // addonAfter="USD" 
              min={0}  />
            </Form.Item>
         
        <div className="form-btns">
          <Button type="primary" ghost onClick={() =>{
            setOpen(false);
            // form.resetFields();
          }}>
            {t.cancel}
          </Button>
          <Button type="primary" loading={loadingAdd} htmlType="submit" className="btn-add-admin">
            {edit ? t.edit : t.submit}
          </Button>
        </div>
      </Form>
        )
      }
    </Modal>
  );
});

export const ModalAppAddCustomer = memo(({ t, open, setOpen, editId, edit, pageCount }) => {
  const [form] = Form.useForm();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const client = useQueryClient();
  const { message } = App.useApp();
  
  const { data: usersDetails, isLoading: loadingUsers } = useQuery({
    queryKey: [queryKeys.getUsersById, editId],
    queryFn: () => getSingleUser(editId),
    keepPreviousData: true,
    enabled: !!edit,
    // select: (data) => data?.results?.find((item) => item.id === editId),
  });
  // console.log('usersDetails:', usersDetails);
  
  const { data: features, isLoading } = useQuery({
    queryKey: [queryKeys.getFeatures],
    queryFn: () => getFeatures(),
    keepPreviousData: true,
  });

  const calculateTotalPrice = (selectedFeatures, discount = 0) => {
    const selectedPrices = features
      .filter((feature) => selectedFeatures.includes(feature?.Name))
      .reduce((acc, feature) => acc + feature?.Price, 0);
      setTotalPrice(selectedPrices);
    // const finalPrice = selectedPrices - (selectedPrices * discount) / 100;
    const finalPrice = selectedPrices -  discount ;
    form.setFieldsValue({ SubscriptionPrice: finalPrice?.toFixed(2) });
    // form.setFieldsValue({ Total: finalPrice?.toFixed(2) });
  };

  const handleFeaturesChange = (selectedFeatures) => {
    const discount = form.getFieldValue('discount') || 0;
    calculateTotalPrice(selectedFeatures, discount);
  };

  const handleDiscountChange = (e) => {
    const discount = e.target.value;
    const selectedFeatures = form.getFieldValue('features') || [];
    calculateTotalPrice(selectedFeatures, discount);
  };

  const { mutate: addUserMutate } = useMutation({
    mutationKey: ['addUser'],
    mutationFn: (body) => (edit ? updateUser({ body, id: editId }) : addUser(body)),
    onSuccess: (data) => {
      setLoadingAdd(false);
      if (data?.message?.status === 500) {
        message.error(data?.message);
        return false;
      }
      message.success(`Customer ${edit ? 'updated' : 'added'} successfully`);
      client.invalidateQueries({ queryKey: [queryKeys.getUsers, pageCount] });
      setOpen(false);
      !edit && form.resetFields();
      setTotalPrice(0);
    },
    onError: (error) => {
      setLoadingAdd(false);
      console.log('error:', error);
    },
  });

  const onFinish = (values) => {
    // console.log('values:', values);
    
    setLoadingAdd(true);
    addUserMutate({
      BrandName: values?.BrandName,
      CustomerEmail: values?.CustomerEmail,
      Password: values?.Password,
      IsSuperUser: false,
      Features: values?.features,
      PhoneNumber: values?.PhoneNumber,
      SubscriptionPrice: values?.SubscriptionPrice,
      ExpiryDate: dayjs(values?.ExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      // Discount: values.discount || 0,
    });
  };
  useEffect(() => {
    if (usersDetails && edit) {
      form.setFieldsValue({
        ...usersDetails,
        ExpiryDate: dayjs(usersDetails?.ExpiryDate) || dayjs(),
        features: usersDetails?.Features || [],
        discount: usersDetails?.Discount || 0,
        // Total: usersDetails?.SubscriptionPrice || 0,
      })
    } else {
      form.resetFields();
    }
  }, [usersDetails, form, edit]);

  return (
    <Modal
      title={edit ? 'Edit Customer' : 'Add Customer'}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      centered
      // width={'50%'}
      width="850px"
    >
        {
          loadingUsers ? (
            <div className="loader"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60vh',
              }}
            >
              <Spin size="large" />
            </div>
          ) : (            
            <Form
              form={form}
              name="add-admin-form"
              layout="vertical"
              onFinish={onFinish}
              style={{ marginTop: '20px' }}
            >
              <Row gutter={[10, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="BrandName"
                      label={t.BrandName}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder={t.BrandName} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="CustomerEmail"
                      label={t.Email}
                      rules={[
                        {
                          required: true,
                          
                        },
                        { type: 'email', message: 'Please enter a valid email' },
      
                      ]}
                    >
                      <Input placeholder={t.Email} disabled={edit} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} 
                  >
                    <Form.Item
                      name="Password"
                      label={t.Password}
                      rules={[
                        {
                          required: edit ? false : true,
                        },
                        {
                          min: 8,
                          message: 'Password must be at least 8 characters',
                        },
                        {
                          max: 20,
                          message: 'Password must be less than 20 characters',
                        },
                      ]}
                    >
                      <Input.Password  placeholder={t.Password} />
                    </Form.Item>
                  </Col>
                   <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="PhoneNumber"
                      label={t.phoneNumber}
                      rules={[
                        {
                          required: true,                 
                          message: 'please enter your phone number',
                        },
                        {
                          validator: (rule, value) => {
                            // console.log(value, 'value');
                            if (!value) {
                              return Promise.reject();
                            }
                            if (isValidPhoneNumber(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject('Please enter a valid phone number');
                          },
                        },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <PhoneInputWithCountrySelect
                        defaultCountry="SA"
                        className="w-full"
                        name="phone"
                        label={t.mobile}
                        international
                        limitMaxLength={true}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="ExpiryDate"
                      label={t.ExpiryDate}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={dayjs()}
                    >
                      <DatePicker showTime allowClear  format="DD-MM-YYYY HH:mm" placeholder={t.ExpiryDate} style={{ width: '100%' }} 
                      // disabledDate={(current) => current && current < dayjs().endOf('day') }
                      disabledDate={(current) => current && current < dayjs().startOf('day')}
                      />
                    </Form.Item>
                  </Col>
                <Col span={24}>
                  <Form.Item
                    name="features"
                    label={t.features}
                    rules={[{ required: true }]}
                  >
                    <Checkbox.Group style={{ width: '100%'}} onChange={handleFeaturesChange} >
                      <Row gutter={[10, 10]}>
                    <Divider plain orientation="left" orientationMargin="0">{t.dashboard}</Divider>
                        {
                          features
                            ?.filter(feature => ['Gender Visits', 'Visitors Volume Graph', 'Total Sales Graph', 'Top Products']?.includes(feature?.Name))
                            ?.map((feature, index) => (
                              <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Checkbox value={feature?.Name}>{feature?.Name} ({feature?.Price}$)</Checkbox>
                              </Col>
                            ))
                        }
                        <Divider plain orientation="left" orientationMargin="0">{t.VisitAnalysis}</Divider>
                        {
                          features
                            ?.filter(feature => ['Busiest Hours',"Gender Distribution by Timeslot","Last 12 Months",'Heat Map']?.includes(feature?.Name))
                            ?.map((feature, index) => (
                              <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Checkbox value={feature?.Name}>{feature?.Name} ({feature?.Price}$)</Checkbox>
                              </Col>
                            ))
                        }
                        <Divider plain orientation="left" orientationMargin="0">{t.SalesAnalysis}</Divider>
                        {
                          features
                            ?.filter(feature => ['Sales by Product Details',"Top 5 Sales By Product_Category","Total Sales by Product_Type","Visit Count vs Sales Conversion Monthly Trend","Visit Count vs Sales Conversion Weekly Trend","Daily Trend Sales"]?.includes(feature?.Name))
                            ?.map((feature, index) => (
                              <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Checkbox value={feature?.Name}>{feature?.Name} ({feature?.Price}$)</Checkbox>
                              </Col>
                            ))
                        }
                        <Row gutter={[10, 0]} style={{width: '100%'}}>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                         <Divider plain orientation="left" orientationMargin="0">{t.MarketingPromotions}</Divider>
                            {
                              features
                                ?.filter(feature => ['Visitor Count']?.includes(feature?.Name))
                                ?.map((feature, index) => (
                                  <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Checkbox value={feature?.Name}>{feature?.Name} ({feature?.Price}$)</Checkbox>
                                  </Col>
                                ))
                            }
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                           <Divider plain orientation="left" orientationMargin="0">{t.asknexta}</Divider>
                            {
                              features
                                ?.filter(feature => ['Ask Store']?.includes(feature?.Name))
                                ?.map((feature, index) => (
                                  <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Checkbox value={feature?.Name}>{feature?.Name} ({feature?.Price}$)</Checkbox>
                                  </Col>
                                ))
                            }
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                           <Divider plain orientation="left" orientationMargin="0">{t.inventory}</Divider>
                            {
                              features
                                ?.filter(feature => ['Inventory']?.includes(feature?.Name))
                                ?.map((feature, index) => (
                                  <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Checkbox value={feature?.Name}>{feature?.Name} ({feature?.Price}$)</Checkbox>
                                  </Col>
                                ))
                            }
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                           <Divider plain orientation="left" orientationMargin="0">{t.ManyCupCoffee}</Divider>
                            {
                              features
                                ?.filter(feature => ['Many Cup Coffee']?.includes(feature?.Name))
                                ?.map((feature, index) => (
                                  <Col key={index} span={24}>
                                    <Checkbox value={feature?.Name}>{feature?.Name} ({feature?.Price}$)</Checkbox>
                                  </Col>
                                ))
                            }
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                           <Divider plain orientation="left" orientationMargin="0">{t.emplyee}</Divider>
                            {
                              features
                                ?.filter(feature => ['Emplyee']?.includes(feature?.Name))
                                ?.map((feature, index) => (
                                  <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Checkbox value={feature?.Name}>{feature?.Name} ({feature?.Price}$)</Checkbox>
                                  </Col>
                                ))
                            }
                          </Col>
                        </Row>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="SubscriptionPrice"
                    // label={t.subTotal}
                    label={t.SubscriptionPrice}
                    rules={[{ required: true }]}
                  >
                    <Input type="number" min={0} disabled placeholder={t.SubscriptionPrice} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
  <Form.Item
    name="discount"
    label={t.Discount}
    rules={[
      ({ getFieldValue }) => ({
        validator(_, value) {
          // const subscriptionPrice = parseFloat(getFieldValue('SubscriptionPrice'));
          const subscriptionPrice = totalPrice;
          const discountValue = parseFloat(value);
          if (discountValue >= subscriptionPrice) {
            return Promise.reject("Discount can't be greater than subscription price.");
          }

          return Promise.resolve();
        },
      }),
    ]}
  >
    <Input
      type="number"
      min={0}
      // step="0.01" 
      placeholder={t.Discount}
      onChange={handleDiscountChange}
    />
  </Form.Item>
</Col>

                {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="Total"
                    label={t.Total}
                    rules={[{ required: true }]}
                  >
                    <Input type="number" min={0} disabled placeholder={t.Total} />
                  </Form.Item>
                </Col> */}
              </Row>
              <div className="form-btns">
                <Button type="primary" ghost onClick={() => setOpen(false)}>
                  {t.cancel}
                </Button>
                <Button type="primary" loading={loadingAdd} htmlType="submit" className="btn-add-admin">
                  {edit ? t.edit : t.submit}
                </Button>
              </div>
            </Form>
          )
        }
    </Modal>
  );
});



