export default {
  language: 'Language',
  dashboard: 'Dashboard',
  analytics: 'Analytics',
  morrow: 'Morrow AI',
  BrandName: 'Brand Name',
  UserID: 'User ID',
  JoinDate: 'Join Date',
  High: '24 High',
  Low: '24 Low',
  branches: 'Branches',
  Pairs: 'Pairs',
  addBranch: 'Add Branch',
  Heatmap: 'Heatmap',
  jobs: 'Jobs',
  blog: 'Blog',
  editBlog: 'Edit Blog',
  Content: 'Content',
  DoyouwanttodeletetheBlog: 'Do you want to delete the blog?',
  current: 'Current',
  date: 'Date',
  slug: 'Slug',
  slugPlaceholder: 'Write slug for blog',
  Volume: 'Volume',
  upload: 'Upload',
  inventory : 'Inventory',
  MarketVolume: 'Market Volume',
  emplyeePerformance: 'Employee Performance',
  id: 'ID',

  title: 'Title',
  image: 'Image',
  addBlog: 'Add Blog',
  description: 'Description',
  noDataMessage: 'No Data Found For Heatmap',
  Email: 'Email',
  blogs: 'Blogs',
  TotalCapacity: 'Total Capacity',
  subTotal: 'Sub Total',
  CustomerName: 'Customer Name',
  Discount: 'Discount',
  delete: 'Delete',
  SubscriptionPrice: 'Subscription Price',
  DoyouwanttodeletetheCustomer: 'Do you want to delete the Customer?',
  branche: 'Branche',
  Password: 'Password',
  CustomerEmail: 'Customer Email',
  Location: 'Location',
  ExpiryDate: 'Expiry Date',
  IsSuperUser: 'Is Super User',
  subscriptionDate: 'Subscription Date',
  cardTitleDah: "Today's Total Visits",
  cardTitleDah2: 'Total Sales Today',
  cardTitleDah3: 'Current Occupancy',
  cardTitleDah4: 'Average Waiting Time (Seconds)',
  target: 'TARGET',
  Total: 'Total',
  today: 'Day',
  week: 'Week',
  man: 'Man',
  woman: 'Woman',
  plan: 'Plan',
  action: 'Action',
  submit: 'Submit',
  featureName: 'Feature Name',
  addFeature: 'Add Feature',
  feature: 'Feature',
  AvarageUse: 'Avarage Use',
  cancel: 'Cancel',
  upgradePlan: 'Upgrade Plan',
  name: 'Name',
  planName: 'Plan Name',
  areyousure: 'Are you sure?',
  kid: 'Kid',
  Editor: 'Editor',
  Currency: 'Currency',
  ManyCupCoffee: 'Many Cup Coffee',
  SearchByName: 'Search By Name',
  SRNo: 'SR No',
  yes: 'Yes',
  ManyCopCoffee: 'Many Cop Coffee',
  no: 'No',
  SearchByClientName: 'Search By Client Name',
  Action: 'Action',
  actions: 'Actions',
  DueDate: 'Due Date',
  Priority: 'Priority',
  Status: 'Status',
  emplyee: 'Employee',
  Task: 'Task',
  ClientName: 'Client Name',
  AssignedTo: 'Assigned To',
  Project: 'Project',
  upgradeYourPlan: 'Upgrade Your Plan',
  price: 'price',
  addplan: 'Add Plan',
  Doyouwanttodeletethebranch: 'Do you want to delete the branch?',
  Number: 'Number',
  edit: 'Edit',
  notification: 'Notification',
  description: 'Description',
  plans: 'Plans',
  newOrder: 'New Order',
  customers: 'Customers',
  active: 'Active',
  inactive: 'Inactive',
  CustomerNumber: 'Customer Number',
  phoneNumber: 'Phone Number',
  AccountStatus: 'Account Status',
  Dateofaccession: 'Date of Accession',
  Client: 'Client',
  addSubfeature: 'Add Sub Feature',
  features: 'Features',
  subfeatures: 'Sub Features',
  subfeatureName: 'Sub Feature Name',
  Visitors: 'Visitors',
  subfeature: 'Sub Feature',
  Weekday: 'Weekday',
  WeekendPublicHolidays: 'Weekend & Public Holidays',
  VisitorCount: 'Visitor Count',
  VisitorCountProfile: 'Visitor Count Profile',
  SalesRevenue: 'Sales Revenue',
  TopProducts: 'Top Products',
  asknexta: 'Ask Store',
  VisitorCount: 'Visitor Count',
  DailyTrend: 'Daily Trend',
  month: 'Month',
  Visits: 'Visits',
  NumberOfCups: 'Number of Cups',
  TimeInsideBar: 'Time Inside Bar',
  Picture: 'Picture',
  WearingGloves: 'Wearing Gloves',
  AvgTimeDoingOrder: 'Avg Time Doing Order',
  Name: 'Name',
  LevelUse: 'Level Use',
  ActualQty: 'Actual Qty',
  PredectionQty: 'Predection Qty',
  AvarageUse: 'Avarage Use',

  TotalSales: 'Total Sales',
  SalesVolume: 'Sales Volume',
  SalesTransaction: 'Sales Transaction',
  Last: 'Last',
  Next: 'Next',
  Typeyourmessagehere: 'Type your message here...',
  Storemanageristyping: 'Store manager is typing',
  ChatwithStore: 'Chat with Store',
  storeHello: 'Hello, I am your store manager. How can I help you?',
  VisitAnalysis: 'Visit Analysis',
  TotalSalesGraph: 'Total Sales Graph',
  Days: 'Days',
  Months: 'Months',
  Sales: 'Sales',
  TransactionCount: 'Transaction Count',
  LastDays: 'Last Days',
  NextDays: 'Next Days',
  VisitCountvsSalesConversionWeeklyTrend:
    'Visit Count vs Sales Conversion Weekly Trend',
  VisitCountvsSalesConversionMonthlyTrend:
    'Visit Count vs Sales Conversion Monthly Trend',
  ThisWeek: 'This Week',
  LastWeek: 'Last Week',
  Week: 'Week',
  BestPerformDay: 'Best Perform Day',
  SalesAnalysis: 'Sales Analysis',
  VisitCount: 'Visit Count',
  SalesConversion: 'Sales Conversion',
  MarketingPromotions: 'Marketing & Promotions',
  Hours: 'Hours',
  SalesByProductDetails: 'Sales by Product Details',
  TopProduct_Type: 'Top 5 Sales By Product_Category',
  Changes: 'Changes',
  TotalSalesbyProductType: 'Total Sales by Product_Type',
  Saturday: 'Saturday',
  InventoryManagement: 'Inventory Management',
  TotalFemaleandMaleandKids: 'Total:(Female and Male and Kids)',
  AverageDailyVisits: 'Average Daily Visits',
  AverageHourlyVisits: 'Average Hourly Visits',
  cardTitleDashThree: 'Daily Visit Trends By Gender',
  cardTitleDashThree2: 'Daily Visit',
  promotinAnalytics: 'Promotin Analytics',
  sectionDashTwo2: 'Visitors Volume Graph',
  gender: 'Gender',
  site: 'Site',
  location: 'Location',
  addCustomer: 'Add Customer',
  DailyTotalVisits: 'Total Daily Visits',
  titleTable: 'All Sites Weekly Visits Summary',
  sectionOneAnalytics: 'Busiest Hours of the Day',
  sectionTwoAnalytics: 'Gender Distribution by Timeslot',
  sectionThreeAnalytics: 'Last 12 Months',
  profile: 'Profile',
  logout: 'Logout',
  ProductCategoryDailySales: 'Product Category Daily Sales',
  changeUser: 'Change User Name',
  userName: 'User Name',
  updateUser: 'Update User Name',
};
