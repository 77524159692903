import { createSlice } from '@reduxjs/toolkit';
import { clearStoredToken, clearStoredUser } from '../../services/user-storage';



const initialState = {
  token: null,
  user: null,
  darkMode: false,
  currentLang: 'en',
  activeBrancheId: null,
  activeBrancheName: null,
  imageActiveBranche: null,
  branchesList: [],
  Features: [],
  admin: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
    setFeatures: (state, action) => {
      state.Features = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setCurrentLang: (state, action) => {
      state.currentLang = action.payload;
    },
    setActiveBrancheId: (state, action) => {
      state.activeBrancheId = action.payload;
    },
    setActiveBrancheName: (state, action) => {
      state.activeBrancheName = action.payload;
    },
    setImageActiveBranche: (state, action) => {
      state.imageActiveBranche = action.payload;
    },
    setBranches: (state, action) => {
      state.branchesList = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      state.branchesList = [];
      state.Features = [];
      state.admin = false;
      state.imageActiveBranche = null;
      state.activeBrancheId = null;
      state.darkMode = false;
      state.activeBrancheName = null;
      state.currentLang = 'en';
      clearStoredToken()
      clearStoredUser()
    },
  },
});


export const { setUser, setToken, clearUser,setImageActiveBranche ,setActiveBrancheName,setActiveBrancheId, setFeatures, setDarkMode, setCurrentLang, setAdmin,setBranches } =
  userSlice.actions;

export default userSlice.reducer;


