export const queryKeys = {
  genderList: 'genderList',
  getGenderVisits: 'getGenderVisits',
  getVisitorsVolumeGraph: 'getVisitorsVolumeGraph',
  getJobs: 'getJobs',
  getGenderTrends: 'getGenderTrends',
  getBusiestHour: 'getBusiestHour',
  getGenderVisitsCards: 'getGenderVisitsCards',
  getLastMonthVisits: 'getLastMonthVisits',
  getBlogs: 'getBlogs',
  getGenderDistribution: 'getGenderDistribution',
  notificationList: 'notificationList',
  getHeatmap: 'getHeatmap',
  getUsers: 'getUsers',
  getFeatures: 'getFeatures',
  getSubFeatures: 'getSubFeatures',
  getBranches: 'getBranches',
  getAllUsers: 'getAllUsers',
  getUsersById: 'getUsersById',
};
